import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NavBar from '../NavBar/NavBar';
import SEO from '../SEO/SEO';
import Hero from '../Hero/Hero';
import Title from '../Title/Title';
import VideoThumbnail from '../VideoThumbnail/VideoThumbnail';
import { Pagination } from '../../utils/pagination';
import { getSafe } from '../../utils/getSafe';
import ScrollRevealAnimation from '../ScrollRevealAnimation/ScrollRevealAnimation';
import { fetchPrismicResourcesEvents, findGetParameter } from '../../utils/prismic';
import { SelectOptions, SelectStyles, DropdownIndicator } from '../../utils/orderBy';
import Select from 'react-select';
import '../../stylesheets/components/ordering.scss';
import './EventPage.scss';

const mapMissingEvents = (missingEvents) => {
  const missing = [];

  for (let i = 0; i < missingEvents; i++) {
    missing.push(
      <div className="column is-12-mobile is-6-tablet is-4-desktop missing-event" key={`m${i}`}>
        <ScrollRevealAnimation animation="fadeIn" className="" delay={(i + 1) * 0.25} offset="50px">
          <img src={require('../../images/events-lookout.png').default} alt="Look out for more" />
        </ScrollRevealAnimation>
      </div>
    );
  }

  return missing;
};

const EventPage = () => {
  const [selectValue, setSelectValue] = useState({ value: 'desc', label: 'NEWEST > OLDEST' });
  const [events, setEvents] = useState([]);
  const [pageInfo, setPageInfo] = useState({ pageCount: 1 });
  const [currentPage, setCurrentPage] = useState(findGetParameter('p'));
  const [displayPagination, setDisplayPagination] = useState(true);

  useEffect(() => {
    let isMounted = true;
    getPrismicResourcesEvents(isMounted, currentPage, selectValue.value);
    return () => {
      isMounted = false;
    };
  }, [currentPage]);

  const getPrismicResourcesEvents = (isMounted, currentPage, ordering = 'desc') => {
    setDisplayPagination(false);
    setEvents([]);
    fetchPrismicResourcesEvents(currentPage, ordering).then((res) => {
      if (res && res.data && res.data.length && isMounted) {
        setPageInfo({ pageCount: res.total_pages });
        setEvents(res.data);
        setDisplayPagination(true);
      }
    });
  };

  const handlerSelect = (event) => {
    setCurrentPage(1);
    getPrismicResourcesEvents(true, 1, event.value);
  };

  const title = { text: 'Events' };
  const missingEvents = events && events.length ? 6 - events.length : 6;
  if (missingEvents < 0) {
    missingEvents = 0;
  }

  return (
    <>
      <SEO title={title} type="WebPage" urlPath="/events" />
      <Hero
        leftImage="garfield45/home-garfield-cake.png"
        rightImage="garfield45/home-garfield-45th-mobile.png"
        rightImageMobile="garfield45/home-garfield-45th-mobile.png"
        classNameExternal="hero-videos"
      />
      <NavBar active="events" />
      <Title fileName="events-title.png" className="page-title" alt="Events Title" />

      <>
        {' '}
        <div className="container event-ordering">
          <label>Order by</label>
          <Select
            options={SelectOptions()}
            value={selectValue}
            onChange={(value) => {
              setSelectValue(value);
              handlerSelect(value);
            }}
            components={{ DropdownIndicator }}
            className="ordering-select"
            classNamePrefix="react-select"
            styles={SelectStyles()}
            isSearchable={false}
          />
        </div>
        <div className="container videos-container">
          <div className="columns is-flex-wrap-wrap">
            {events &&
              events.length > 0 &&
              events.map((event, i) => {
                const data = getSafe(() => event.data, {});
                const eventLocation = getSafe(() => data.event_location[0].text, null);
                const title = getSafe(() => data.title[0].text, null);
                let url = getSafe(() => event.uid, null);
                if (url) {
                  url = '/events/' + url;
                }
                const image = data.image.url;

                return (
                  <div className="column is-12-mobile is-6-tablet is-4-desktop" key={i}>
                    <ScrollRevealAnimation animation="fadeIn" className="" delay={i * 0.25} offset="50px">
                      <VideoThumbnail
                        title={title}
                        thumbnail={image}
                        description={eventLocation}
                        playIconClass="event-button"
                        url={url}
                      />
                    </ScrollRevealAnimation>
                  </div>
                );
              })}

            {missingEvents && mapMissingEvents(missingEvents)}
          </div>
        </div>
        <Pagination
          pageInfo={pageInfo}
          slug="events"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          displayPagination={displayPagination}
        />
      </>
    </>
  );
};

EventPage.propTypes = {};

const mapStateToProps = ({ blocked }) => {
  return { blocked };
};

export default connect(mapStateToProps, null)(EventPage);
