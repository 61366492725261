import React from 'react';
import { Layout } from '../components/Layout';
import Loading from '../components/Loading/Loading';
import EventPage from '../components/EventPage/EventPage';

const Events = ({ data }) => {
  return (
    <Layout className="events-page">
      <Loading />
      <EventPage />
    </Layout>
  );
};

export default Events;
